import '../css/core.scss'

import Vue from 'vue'
import Vuetify from 'vuetify'
import theme from '../js/theme/indigo'
import Index from '../template/index'

Vue.use(Vuetify, {theme})

document.addEventListener('DOMContentLoaded', () => {
  let el = document.createElement('div');
  document.body.appendChild(el);

  const app = new Vue({
    el,
    render: h => h(Index)
  })
})