<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" temporary app></v-navigation-drawer>
    <v-toolbar color="indigo" dark app>
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-toolbar-title>WELLREAD8</v-toolbar-title>
    </v-toolbar>
    <v-content>
      <v-container fluid>
        <h1>Hello Wellread8</h1>
      </v-container>
    </v-content>
    <v-footer height="80" padless>
      <v-card-text style="text-align: center">
        <a href="http://beian.miit.gov.cn" target="_blank" style="color: #666; text-decoration: none;">工业和信息化部备案管理系统网站 蒙ICP备18005813号-1</a>
      </v-card-text>
    </v-footer>
  </v-app>
</template>
<script>
  export default {
    name: 'Home',
    data() {
      return {
        drawer: null
      }
    }
  }
</script>